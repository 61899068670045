import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export type ArkUserAvatarSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'ark-user-avatar',
  standalone: true,
  imports: [
    MatIconModule,
  ],
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss'
})
export class ArkUserAvatarComponent {
  @Input()
  src?: string | null;

  @Input()
  size: ArkUserAvatarSize = 'medium';

  hasError = false;
}
